export const introSectionContent = {
  title: {
    pl: 'Do Pobrania',
    en: 'Files',
  },
  back: {
    pl: 'Materiały',
    en: 'Downloadable',
  },
  desc: {
    pl: '',
    en: '',
  },
}

const forMedia = {
  title: {
    pl: 'Dla Mediów',
    en: 'For Media',
  },
  back: {
    pl: 'Materiały',
    en: 'Assets',
  },
  desc: {
    pl: '',
    en: '',
  },
  files: [],
}

const forCustomers = {
  title: {
    pl: 'Dla Klientów',
    en: 'For Customers',
  },
  back: {
    pl: 'Materiały',
    en: 'Assets',
  },
  desc: {
    pl: '',
    en: '',
  },
  files: [
    {
      title: {
        pl: 'Polityka Bezpieczeństwa ISO 28000',
        en: 'Polityka Bezpieczeństwa ISO 28000',
      },
      // to: 'https://cargonite.pl/pdf/policy-security-pl.pdf',
      to: 'https://cargonite.pl/pdf/polityka-bezpieczenstwa-iso-28000.pdf',
    },
    {
      title: {
        pl: 'Polityka Jakości',
        en: 'Polityka Jakości',
      },
      to: 'https://cargonite.pl/pdf/policy-quality-pl.pdf',
    },
    {
      title: {
        pl: 'Zgłoszenie Reklamacji',
        en: 'Zgłoszenie Reklamacji',
      },
      to: 'https://cargonite.pl/pdf/complaint-letter-pl.pdf',
    },
    {
      title: {
        pl: 'Ogólne Warunki Przewozu 2022',
        en: 'General Terms and Conditions of Transport 2022',
      },
      to: {
        pl: 'https://cargonite.pl/pdf/OWP-2022.pdf',
        en: 'https://cargonite.pl/pdf/OWP-2022-en.pdf',
      },
    },
    {
      title: {
        pl: 'Polityka Ochrony Danych Osobowych',
        en: 'Personal Data Protection Policy ',
      },
      to: {
        pl: 'https://cargonite.pl/pdf/podo-pl.pdf',
        en: 'https://cargonite.pl/pdf/podo-en.pdf',
      },
    },
    {
      title: {
        pl: 'NovaTMS',
        en: 'NovaTMS',
      },
      to: 'https://cargonite.pl/pdf/NovaTMS.pdf',
    },
  ],
}

const forCarriers = {
  title: {
    pl: 'Dla Przewoźników',
    en: 'For Carriers',
  },
  back: {
    pl: 'Materiały',
    en: 'Assets',
  },
  desc: {
    pl: '',
    en: '',
  },
  files: [
    {
      title: { pl: 'Ogólne Warunki Przewozu', en: 'Ogólne Warunki Przewozu' },
      to: `https://cargonite.pl/pdf/general-cargo-terms-pl.pdf`,
    },
    {
      title: {
        pl: 'Instytutowe Klauzule Ładunkowe',
        en: 'Instytutowe Klauzule Ładunkowe',
      },
      to: `https://cargonite.pl/pdf/cargo-clause-pl.pdf`,
    },
    {
      title: {
        pl: 'Ogólne Polskie Warunki Spedycyjne 2010 (OPWS 2010)',
        en: 'Ogólne Polskie Warunki Spedycyjne 2010 (OPWS 2010)',
      },
      to: `https://cargonite.pl/pdf/general-polish-carring-terms-pl.pdf`,
    },
    {
      title: {
        pl: 'Polityka Zintegrowanego Systemu Zarządzania Jakością i Środowiskiem',
        en: 'Polityka Zintegrowanego Systemu Zarządzania Jakością i Środowiskiem',
      },
      to: `https://cargonite.pl/pdf/policy-zsz-pl.pdf`,
    },
  ],
}

const forDrivers = {
  title: {
    pl: 'Dla Kierowców',
    en: 'For Drivers',
  },
  back: {
    pl: 'Materiały',
    en: 'Assets',
  },
  desc: {
    pl: '',
    en: '',
  },
  files: [],
}

// const forBeginners = {
//   title: {
//     pl: 'Dla Początkujących',
//     en: 'For Beginners',
//   },
//   back: {
//     pl: 'Materiały',
//     en: 'Assets',
//   },
//   desc: {
//     pl: '',
//     en: '',
//   },
//   files: [{ title: { pl: 'Instrukcja', en: 'Manual' }, to: '/pdf/blank.pdf' }],
// }

export const filesSections = [
  forMedia,
  forCustomers,
  forCarriers,
  forDrivers,
  // forBeginners,
]
