import React from 'react'
import { Link } from 'gatsby'
import { filesSections } from '../content'
import { useLangContext } from '../../../context/lang.context'
import Headline from '../../../components/Headline'
import { alpha, colors, s } from '../../../style'

const FilesSections = () => {
  const { lang } = useLangContext()

  return (
    <>
      {filesSections
        .filter(({ files }) => files.length > 0)
        .map((section, id) => (
          <section key={id}>
            <Headline
              h2
              title={section.title[lang]}
              back={section.back[lang]}
              desc={section.desc[lang]}
            />
            <div css={sOuter}>
              <div css={sInner}>
                {section.files
                  .filter(({ title }) => title[lang])
                  .map(({ to, title }, id) => {
                    let linkDoc = typeof to === 'object' ? to[lang] : to
                    return linkDoc.includes('http') ? (
                      <a
                        key={id}
                        css={sFile}
                        href={linkDoc}
                        target='_blank'
                        rel='noreferrer noopener'>
                        {title[lang]}
                      </a>
                    ) : (
                      <Link key={id} css={sFile} to={linkDoc}>
                        {title[lang]}
                      </Link>
                    )
                  })}
              </div>
            </div>
          </section>
        ))}
    </>
  )
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '0 auto',
}

const gap = 50

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px 100px',
  gap: gap,
  [s.xs]: {
    margin: '0 25px 100px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 200px',
  },
}

const sFile = {
  cursor: 'pointer',
  fontSize: '0.875rem',
  [s.sm_down]: {
    maxWidth: '100%',
    flexBasis: '100%',
    padding: 25,
  },
  [s.sm]: {
    padding: 50,
  },
  [s.md]: {
    maxWidth: `calc(50% - ${((2 - 1) * gap) / 2}px)`,
    flexBasis: `calc(50% - ${((2 - 1) * gap) / 2}px)`,
    padding: '37px 50px',
  },
  [s.lg]: {
    padding: 50,
    fontSize: '1rem',
  },
  transition: 'all 0.3s ease-out',
  backgroundColor: colors.white.concat(alpha[12]),
  fontWeight: 'bold',
  boxShadow: `inset 0px 0px 0px 4px ${colors.main.concat(alpha[0])}`,
  '&:hover': {
    boxShadow: `inset 0px 0px 0px 4px ${colors.main}`,
    color: colors.main,
  },
}

export default FilesSections
