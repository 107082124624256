import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { introSectionContent as content } from '../content'
import { useLangContext } from '../../../context/lang.context'
import SectionImage from '../../../components/SectionImage'

const IntroSection = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      image: file(absolutePath: { regex: "/images/files/intro.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const image = getImage(query.image)

  return (
    <SectionImage
      title={content.title[lang]}
      desc={content.desc[lang]}
      back={content.back[lang]}
      image={image}
      imagePosition='center center'
    />
  )
}

export default IntroSection
