export const seo = {
  title: {
    pl: 'Materiały do Pobrania',
    en: 'Downloadable Files',
  },
  description: {
    pl: 'Pobierz dokumenty firmowe, materiały dla przewoźników, kierowców oraz klientów',
    en: 'Download company documents, materials for carriers, drivers and customers',
  },
}
