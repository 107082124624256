import React from 'react'
import Layout from '../components/Layout'
import Files from '../page/Files/Files'

const FilesPage = () => (
  <Layout>
    <Files />
  </Layout>
)

export default FilesPage
